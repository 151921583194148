import React from 'react'
import { BaseTable, createTurfBbox, lineBreak, PlotMap } from './Shared'

const PlotDetails = ({
  googleApiKey,
  multiPlots,
  mapboxToken,
  mapTypeSatellite,
  plot,
  plotGeometry
}) => {
  const baseUrl = `https://api.mapbox.com/styles/v1/${mapTypeSatellite}/static`
  const width = 580
  const height = 420
  const bbox = createTurfBbox(plot.geometry_to_geojson, 1)
  const location = `[${bbox[0]},${bbox[1]},${bbox[2]},${bbox[3]}]`
  const geometryToGeojson = {
    type: 'Feature',
    geometry: plotGeometry,
    properties: {
      'stroke': '#d95d57',
      'stroke-width': 2,
      'fill-opacity': 0
    }
  }
  const geojsonString = JSON.stringify(geometryToGeojson)
  const encodedGeoJSON = `geojson(${encodeURIComponent(geojsonString)})`
  const mapboxStaticImageUrl = `${baseUrl}/${encodedGeoJSON}/${location}/${width}x${height}@2x?access_token=${mapboxToken}`

  const streetViewUrl = `https://maps.googleapis.com/maps/api/streetview?size=400x256&location=${plot.coordinates.lat},${plot.coordinates.lng}&key=${googleApiKey}`

  return (
    <div className="d-flex flex-column pdf-content-inside">
      <div className="page-2-col align-start">
        <div>
          <BaseTable className="col-2 mb-12">
            <thead>
              <tr><th colSpan="2">Terrain{multiPlots ? 's' : ''}</th></tr>
            </thead>
            <tbody>
              <tr>
                <td>Parcelle{multiPlots ? 's' : ''} :</td>
                <td>{lineBreak(plot.land_registry_id_short.join(', '))}</td>
              </tr>
              <tr>
                <td>Surface terrain{multiPlots ? 's' : ''} :</td>
                <td>{plot.area.toLocaleString('fr-FR')}<span> m²</span></td>
              </tr>
            </tbody>
          </BaseTable>

          <BaseTable className="col-2 mb-12">
            <thead>
              <tr><th colSpan="2">Bâtiment{plot.buildings_nbr > 1 ? 's' : ''}</th></tr>
            </thead>
            <tbody>
              <tr>
                <td>Nombre :</td>
                {plot.buildings_nbr > 0 ? <td>{plot.buildings_nbr}</td> : <td className='no-bold'>-</td>}
              </tr>
              <tr>
                <td>Hauteur maximale :</td>
                {plot.max_height ? <td>{plot.max_height}<span> m</span></td> : <td className='no-bold'>-</td>}
              </tr>
              <tr>
                <td>Emprise au sol actuelle :</td>
                {plot.ces > 0 ? <td>{Math.round(plot.ces * 100).toLocaleString('fr-FR')}<span> %</span></td> : <td className='no-bold'>-</td>}
              </tr>
              <tr className="empty" />
              <tr>
                <td>Surface logements :</td>
                {plot.building_area_housing > 0 ? <td>{Math.round(plot.building_area_housing).toLocaleString('fr-FR')}<span> m²</span></td> : <td className='no-bold'>-</td>}
              </tr>
              <tr>
                <td>Surface tertiaire :</td>
                {plot.building_area_business ? <td>{Math.round(plot.building_area_business).toLocaleString('fr-FR')}<span> m²</span></td> : <td className='no-bold'>-</td>}
              </tr>
            </tbody>
          </BaseTable>
        </div>

        <div>
          <PlotMap url={mapboxStaticImageUrl} className="mb-12" />
          <div className="google-pic">
            <img src={streetViewUrl} alt="Google Street View" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PlotDetails
