const font = { size: 10, family: 'Gilroy' }
const colorFont = { font, color: '#999999' }

// Fonction pour interpoler les valeurs manquantes en fonction des valeurs précédentes et suivantes, pour rendre le graphique linéaire
const interpolateNullValues = (data) => {
  const keys = Object.keys(data)
  const values = Object.values(data)

  for (let i = 0; i < values.length; i++) {
    if (values[i] === null) {
      let prevValue = null
      let nextValue = null
      let prevIndex = i - 1
      let nextIndex = i + 1

      while (prevIndex >= 0 && prevValue === null) {
        prevValue = values[prevIndex]
        prevIndex--
      }

      while (nextIndex < values.length && nextValue === null) {
        nextValue = values[nextIndex]
        nextIndex++
      }

      if (prevValue !== null && nextValue !== null) {
        values[i] = (prevValue + nextValue) / 2
      }
      else if (prevValue !== null) {
        values[i] = prevValue
      }
      else if (nextValue !== null) {
        values[i] = nextValue
      }
    }
  }

  const interpolatedData = {}
  keys.forEach((key, index) => {
    interpolatedData[key] = values[index]
  })

  return interpolatedData
}

const chartTitle = (title) => ({
  display: true,
  text: title,
  padding: { top: 10, bottom: 0 },
  color: '#31325F',
  align: 'start',
  font: {
    family: 'Gilroy, sans-serif',
    size: 14,
    weight: 600,
  }
})

const chartSubTitle = {
  display: true,
  text: 'Au cours des 12 derniers mois',
  padding: { top: 0, bottom: 30 },
  align: 'start',
  color: '#31325F',
  font: {
    family: 'Gilroy, sans-serif',
    size: 12,
    weight: 400,
  },
}

const baseFormat = (datas) => ({
  type: 'bar',
  data: {
    labels: datas.map((data) => data.label),
    datasets: [
      {
        label: 'Ville',
        data: datas.map((data) => data.data1),
        backgroundColor: '#ffeb78',
        borderRadius: 10,
      },
      {
        label: 'Dépt.',
        data: datas.map((data) => data.data2),
        backgroundColor: '#639A5F',
        borderRadius: 10,
      }
    ],
  },
  options: {
    responsive: true,
    devicePixelRatio: 3,
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          boxWidth: 4,
          boxHeight: 4,
          usePointStyle: true,
          filter: (item, chart) => (item.text !== 'hidden'),
          font,
          color: '#999999',
        },
      },
      datalabels: {
        display: false,
      }
    },
    scales: {
      y: {
        grid: { drawTicks: false },
        border: { dash: [5, 5], color: 'transparent' },
        ticks: {
          font,
          color: '#999999',
          min: 0,
          stepSize: 0.1,
          callback: (value) => ((value * 100).toFixed(0) + '%' )
        },
      },
      x: {
        font,
        color: '#999999',
        grid: { display: false },
        border: { color: 'transparent' }
      }
    },
    categoryPercentage: 0.2,
    barPercentage: 0.5,
  }
})

const transactionsFormat = (datas) => {
  const newFormat = baseFormat(datas)
  newFormat.data.datasets = [
    {
      label: 'hidden',
      data: datas.map((data) => data.data1),
      backgroundColor: '#31325F',
      borderRadius: 10,
      barThickness: 6,
    },
  ]
  newFormat.options.plugins.title = chartTitle("Nombre d'appartements vendus par typologie *")
  newFormat.options.plugins.subtitle = chartSubTitle
  newFormat.options.scales.x.ticks = colorFont
  newFormat.options.scales.y.ticks.callback = (value) => (value + ' biens')
  newFormat.options.scales.y.ticks.stepSize = 4
  newFormat.options.plugins.datalabels = {
    align: 'end',
    anchor: 'end',
    color: '#999999',
    formatter: (value, context) => (value !== 0 ? value : ''),
    font,
  }
  return newFormat
}

const pricesFormat = (datas) => {
  const newFormat = baseFormat(datas)
  newFormat.type = 'scatter'

  const borderRadius = {
    topLeft: 10,
    topRight: 10,
    bottomLeft: 10,
    bottomRight: 10
  }

  newFormat.data.datasets = [
    {
      type: 'bar',
      label: 'hidden',
      data: datas.map((data) => ({
        x: data.label,
        y: [data.data2, data.data3],
      })),
      borderRadius,
      barPercentage: 0.3,
      categoryPercentage: 0.3,
      backgroundColor: 'rgba(242, 246, 250, 0.8)',
      order: 2,
    },
    {
      type: 'scatter',
      label: 'hidden',
      data: datas.map((data) => ({
        x: data.label,
        y: data.data1
      })).filter(point => point.y !== 0),
      backgroundColor: 'rgba(49, 50, 95, 1)',
      pointRadius: 4,
      order: 1,
    }
  ]
  newFormat.options.plugins.title = chartTitle("Prix/m² moyen par typologie d'appartement *")
  newFormat.options.plugins.subtitle = chartSubTitle
  newFormat.options.scales.y.ticks.callback = (value) => (Math.round(value / 1000).toLocaleString('fr-FR') + ' K€/m²')
  newFormat.options.scales.y.beginAtZero = false
  newFormat.options.scales.y.ticks.stepSize = 1000
  newFormat.options.scales.x.ticks = colorFont
  newFormat.options.scales.x.type = 'category'
  newFormat.options.scales.x.labels = datas.map((data) => data.label)
  newFormat.options.plugins.datalabels = {
    align: 'left',
    color: '#999999',
    font,
    formatter: (value, context) => ((value.y !== 0 && context.dataset.type === 'scatter' ? ((Math.round(value.y / 100) / 10).toLocaleString('fr-FR') + 'K') : '')),
    padding: { left: 10 },
  }
  return newFormat
}

const evolutionFormat = (datas) => {
  const newFormat = baseFormat(datas)
  newFormat.options.plugins.title = chartTitle('Evolution des prix de vente')
  newFormat.type = 'line'
  newFormat.data.datasets[0].backgroundColor = '#31325F'
  newFormat.data.datasets[0].label = 'Appartements'
  newFormat.data.datasets[1].backgroundColor = '#A6C3DB'
  newFormat.data.datasets[1].label = 'Maisons'
  newFormat.options.plugins.title.padding.bottom = 20
  newFormat.options.scales.x.ticks = colorFont
  newFormat.options.scales.y.ticks.stepSize = 1000
  newFormat.options.scales.y.ticks.padding = 15
  newFormat.options.scales.y.ticks.callback = (value) => (Math.round(value / 1000).toLocaleString('fr-FR') + ' K€/m²')
  newFormat.options.maintainAspectRatio = false
  return newFormat
}

const stocksFormat = (datas, color) => {
  const newFormat = baseFormat(datas)
  const maxValue = Math.round(Math.max(...datas.map((data) => data.data1)) * 1.1)
  delete newFormat.options.scales.y.ticks.callback
  delete newFormat.options.categoryPercentage
  delete newFormat.options.barPercentage
  newFormat.data.datasets[0].backgroundColor = color
  newFormat.data.datasets[0].barThickness = 6
  newFormat.data.datasets.push({
    data: datas.map(() => maxValue),
    backgroundColor: '#E0E0E0',
    barThickness: 6,
    borderRadius: 10,
  })

  newFormat.options.indexAxis = 'y'
  newFormat.options.plugins.legend.display = false
  newFormat.options.scales.y.grid.display = false
  newFormat.options.plugins.datalabels = {
    align: 'end',
    anchor: 'end',
    color,
    formatter: (value, context) => (value !== maxValue ? value : ''),
  }

  newFormat.options.scales.y.ticks.align = 'start'
  newFormat.options.scales.y.ticks.crossAlign = 'start'
  newFormat.options.scales.y.stacked = true
  newFormat.options.scales.y.ticks = colorFont

  newFormat.options.scales.x.ticks = colorFont
  newFormat.options.scales.x.max = Math.max(datas.map((d) => d.data1)) + 2
  return newFormat
}

export const roomsDistribution = (inseeData, depInseeData) => {
  const rawData = ['t1', 't2', 't3', 't4', 't5'].map((type, index) => (
    {
      id: index + 1,
      label: type === 't5' ? 'T5+' : type.toUpperCase(),
      data1: inseeData[type] / inseeData.ens,
      data2: depInseeData[type] / depInseeData.ens
    }))

  const newFormat = baseFormat(rawData)
  newFormat.options.scales.x.ticks = colorFont
  return newFormat
}

export const completionYearDistribution = (inseeData, depInseeData) => {
  const rawData = [
    ['b1919', ['Avant', '1919']], ['f1919', ['1919', '1945']], ['f1946', ['1946', '1970']], ['f1971', ['1971', '1991']], ['f1991', ['1991', '2005']], ['f2006', ['2006', '2017']]
  ].map((type, index) => (
    {
      id: index,
      label: type[1],
      data1: inseeData[type[0]] / inseeData.ens,
      data2: depInseeData[type[0]] / depInseeData.ens
    }
  ))

  const newFormat = baseFormat(rawData)
  newFormat.options.scales.x.ticks = colorFont
  return newFormat
}

export const ageDistribution = (inseeData, depInseeData) => {
  const rawData = [['f00', '0 - 14'], ['f15', '15 - 29'], ['f30', '30 - 44'], ['f45', '45 - 59'], ['f60', '60 - 74'], ['f75', '75 et +']].map((type, index) => (
    {
      id: index,
      label: type[1],
      data1: inseeData[type[0]] / inseeData.ens,
      data2: depInseeData[type[0]] / depInseeData.ens
    }
  ))

  const newFormat = baseFormat(rawData)
  newFormat.options.scales.x.ticks = colorFont
  return newFormat
}

const transactionsDistributionData = (appartments, key) => (
  Object.keys(appartments).map((k) => (
    {
      id: 1,
      label: k === 't5' ? 'T5+' : k.toUpperCase(),
      data1: appartments[k][key],
      data2: appartments[k].square_meter_price_d1,
      data3: appartments[k].square_meter_price_d9,
    }
  ))
)

export const sellsDistribution = (appartments) => {
  delete appartments.moy
  const newFormat = transactionsFormat(transactionsDistributionData(appartments, 'nbr'))
  newFormat.options.maintainAspectRatio = false
  return newFormat
}

export const priceDistribution = (appartments) => {
  delete appartments.moy
  const newFormat = pricesFormat(transactionsDistributionData(appartments, 'square_meter_price'))
  newFormat.options.maintainAspectRatio = false
  newFormat.options.scales.y.ticks.stepSize = 1000
  return newFormat
}

export const priceEvolution = (appartments, houses) => {
  const dataAppartmentsFormatted = interpolateNullValues(appartments)
  const dataHousesFormatted = interpolateNullValues(houses)
  const rawData = ['2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023'].map((year, index) => (
    {
      id: index,
      label: year,
      data1: dataAppartmentsFormatted[year + "-01-01"],
      data2: dataHousesFormatted[year + "-01-01"],
    }
  ))

  return evolutionFormat(rawData)
}

export const stocksDistribution = (datas, color) => {
  const rawData = ['t1', 't2', 't3', 't4', 't5'].map((type, index) => (
    {
      id: index + 1,
      label: type === 't5' ? 'T5+' : type.toUpperCase(),
      data1: datas[type].nbr,
    }))
  return stocksFormat(rawData, color)
}

export const reservationsDistribution = (datas, color) => {
  const rawData = ['t1', 't2', 't3', 't4', 't5'].map((type, index) => (
    {
      id: index + 1,
      label: type === 't5' ? 'T5+' : type.toUpperCase(),
      data1: datas[type].nbr,
    }))
  return stocksFormat(rawData, color)
}
