import React from 'react'
import { Header, Footer, ReportWrapper, simplifiedGeometry, cityWithoutDVF } from './Shared'
import CitySummary from './CitySummary'
import GeneralSummary from './GeneralSummary'
import RealEstateData from './RealEstateData'
import SocioEconomicData from './SocioEconomicData'
import OldRealEstateData from './OldRealEstateData'
import NewRealEstateData from './NewRealEstateData'
import NearPrograms from './NearPrograms'
import DetailedProgram from './DetailedProgram'

const MarketReport = ({
  city,
  depInseeData,
  inseeData,
  mapboxToken,
  mapTypeLight,
  pages,
  plot,
}) => {

  const date = new Date()
  const allPrograms = plot?.all_programs
  const programs = allPrograms?.programs || []
  const vefaPrograms = allPrograms?.vefa_programs || []
  const indexForVefaProgram = programs.length
  const plotGeometry = simplifiedGeometry(plot.geometry_to_geojson, 0.00000005)
  const programComponent = (program, index, vefa) => (
    <div className="pdf-content" key={index}>
      <Header title="Détail par programme" address={plot.postal_address} />
      <DetailedProgram
        city={city}
        generalIndex={indexForVefaProgram}
        plot={plot}
        program={program}
        programIndex={index}
        vefa={vefa}
      />
      <Footer date={date} />
    </div>
  )

  return (
    <ReportWrapper>
      { pages.includes(0) && (
        <div className="pdf-content">
          <Header address={plot.postal_address} title="Étude de marché" />
          <GeneralSummary
            city={city}
            mapboxToken={mapboxToken}
            mapType={mapTypeLight}
            plot={plot}
            plotGeometry={plotGeometry}
            report="marketReport"
          />
          <Footer date={date} />
        </div>
      )}

      { pages.includes(1) && (
        <div className="pdf-content">
          <Header address={plot.postal_address} title="Synthèse générale sur la ville*" />
          <CitySummary
            city={city}
            inseeData={inseeData}
            mapboxToken={mapboxToken}
            mapTypeLight={mapTypeLight}
            plot={plot}
            report="marketReport"
          />
          <Footer date={date} />
        </div>
      )}

      { pages.includes(2) && (
        <div className="pdf-content">
          <Header address={plot.postal_address} title="Données sur le parc immobilier*" />
          <RealEstateData
            depInseeData={depInseeData}
            inseeData={inseeData}
          />
          <Footer date={date} />
        </div>
      )}

      { pages.includes(3) && (
        <div className="pdf-content">
          <Header address={plot.postal_address} title="Données socio-économiques*" />
          <SocioEconomicData
            depInseeData={depInseeData}
            inseeData={inseeData}
          />
          <Footer date={date} />
        </div>
      )}

      { pages.includes(4) && !cityWithoutDVF(city.code) && (
        <div className="pdf-content">
          <Header address={plot.postal_address} title="Données marché de l'ancien" />
          <OldRealEstateData
            city={city}
            plot={plot}
          />
          <Footer date={date} />
        </div>
      )}

      { pages.includes(5) && !cityWithoutDVF(city.code) && (
        <div className="pdf-content">
          <Header address={plot.postal_address} title="Données marché du neuf" />
          <NewRealEstateData
            city={city}
            plot={plot}
          />
          <Footer />
        </div>
      )}

      { pages.includes(6) && (
        <div className="pdf-content">
          <Header address={plot.postal_address} title="Programmes neufs à proximité" />
          <NearPrograms
            allPrograms={allPrograms}
            mapboxToken={mapboxToken}
            mapTypeLight={mapTypeLight}
            plot={plot}
          />
          <Footer date={date} />
        </div>
      )}

      { pages.includes(7) && programs.length > 0 && (
        <div>
          { programs.map((el, index) => programComponent(el, index, false)) }
        </div>
      )}

      { pages.includes(7) && vefaPrograms.length > 0 && (
        <div>
          { vefaPrograms.map((el, index) => programComponent(el, index, true)) }
        </div>
      )}
    </ReportWrapper>
  )
}

export default MarketReport
