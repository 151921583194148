import React, { useEffect, useState } from 'react'
import { useForm } from '../../context/form.context'
import { formatBodyForSendingForm, postForm, retrieveEstimation } from '../SimpleForm/api'
import { RequestRejection } from '../SimpleForm/AppartmentRejection'

export function WaitingPage({ increment }) {
  const [loading, setLoading] = useState('LOAD')
  const [error, setError] = useState(null)
  const [animationEnd, setAnimationEnd] = useState('START')
  const [slug, setSlug] = useState(null)
  const {
    setEstimate, estimation = {}, address = '', property = {
      category: 0,
      area: 0,
      squareMeter: 0,
      condition: 0
    }, user = {}
  } = useForm()

  useEffect(() => {
    retrieveEstimation(address, property.squareMeter, property.area, property.rooms, property.category)
      .then((value) => {

        setEstimate({
          confidenceMin: Math.round(value.confidence_min),
          confidenceMax: Math.round(value.confidence_max),
          predictedPrice: Math.round(value.predicted_price),
          confidenceIndex: value.confidence_index,
          slug: value.slug
        })
        const esti = {
          confidenceMin: Math.round(value.confidence_min),
          confidenceMax: Math.round(value.confidence_max),
          predictedPrice: Math.round(value.predicted_price),
          confidenceIndex: value.confidence_index,
          slug: value.slug
        }
        setSlug(value.slug)

        const format = formatBodyForSendingForm({ ...user, ...property, ...esti })

        postForm(format).then((response) => {
          setLoading('LOADED')
        }).catch(error => console.log('error posting form - push to error service', error))
      }).catch((err) => {
        const esti = {
          confidenceMin: null,
          confidenceMax: null,
          predictedPrice: null,
          confidenceIndex: null,
          slug: null
        }
        const format = formatBodyForSendingForm({ ...user, ...property, ...esti })
        postForm(format).then((response) => {
          setLoading('ERROR')
        }).catch(error => setLoading('ERROR'))
      })
  }, [])

  // useEffect(() => {
  //   setTimeout(() => {
  //     setAnimationEnd('END')
  //   }, 7000)
  // }, [])

  useEffect(() => {
    if (loading === 'LOADED') {
      // increment()
      if (typeof dataLayer !== 'undefined') {
        dataLayer.push({ 'event': 'form-completed' })
      }

      if (slug) {
        window.location.href = `/estimation-immobiliere/formulaire/resultats?id=${slug}`
      }
    }
  }, [loading, animationEnd])

  if (loading === 'ERROR') {
    return <RequestRejection />
  }
  return (
    <div className="container-waiting" id="form-loading">
      <div className="container-loader-text">
        <Loader time={900} label="Test ok le text" id="loaderone" />
        <p>Sollicitation des bases de données</p>
      </div>
      <div className="container-loader-text">
        <Loader time={4500} label="Test ok le text" id="loadertwo" />
        <p>Calcul de l’estimation de la valeur actuelle de votre bien</p>
      </div>
      <div className="container-loader-text">
        <Loader time={8000} label="Test ok le text" id="loaderthree" />
        <p>Calcul de l’indice de pertinence</p>
      </div>
    </div>
  )
}

function Loader({ id, time }) {
  const completeLoader = () => {
    const circle = document.querySelector(`#${id} .circle-loader`)
    const checkmark = document.querySelector(`#${id} .checkmark`)
    circle.classList.toggle('load-complete')
    checkmark.classList.toggle('checkmark-display')
  }

  useEffect(() => {
    const timeoutLoader = setTimeout(() => completeLoader(), time)
    return () => {
      clearTimeout(timeoutLoader)
    }
  }, [])

  return (
    <div className="loader-checkmark-waiting" id={id}>
      <div className="circle-loader">
        <div className="checkmark draw" />
      </div>
    </div>
  )
}
