import React from 'react'
import styled from 'styled-components'
import { device } from '../../stylesheets/device/device'
import TeamPicture from '../../images/desktop/calculator/Team.png'
import HouseIcon from '../../images/desktop/calculator/House.svg'
import Logotype from '../../images/v2/logotype.svg'
import { formatter } from './Results'

const Sidebar = ({ answers }) => (
  <Aside>
    <div className="floating">
      <nav>
        <a href="/">
          <img src={Logotype} alt="Logo City&You" />
        </a>
      </nav>
      <img src={HouseIcon} alt="Maison" />
      <ul>
        { answers.propertyType.label && <li>{answers.propertyType.label}</li> }
        { answers.address.complete && <li>{answers.address.content}</li> }
        { answers.isPrimaryHouse !== null && <li>{answers.isPrimaryHouse === 'true' ? 'Résidence principale' : 'Pas résidence principale'}</li> }
        { answers.frenchResident !== null && <li>{answers.frenchResident === 'true' ? 'Résident en France' : 'Résident hors de France'}</li> }
        { answers.soleOwner !== null && <li>{answers.soleOwner === 'true' ? 'Unique propriétaire' : 'Propriétaires multiples'}</li> }
        { answers.ownershipOrigin.label && <li>{answers.ownershipOrigin.label}</li> }
        { answers.buyingDate && <li>Date d'acquisition : {answers.buyingDate.format('L')}</li> }
        { answers.sellingDate && <li>Date de cession : {answers.sellingDate.format('L')}</li> }
        { answers.buyingPrice && <li>Prix d'acquisition : {formatter.format(answers.buyingPrice)} €</li> }
        { answers.sellingPrice && <li>Prix de vente : {formatter.format(answers.sellingPrice)} €</li> }
        { answers.sellingPriceEstimate && <li>Prix de vente estimé : {formatter.format(answers.sellingPriceEstimate)} €</li> }
        { answers.sellingPricePropDev && <li>Prix de vente promoteur : {formatter.format(answers.sellingPricePropDev)} €</li> }
      </ul>
      <div className="team-aside">
        <img src={TeamPicture} alt="Equipe City&You" />
        <div className="team-text">
          <strong>L’équipe City&You à votre service</strong>
          <p>Pour toute précision complémentaire, <a href="mailto:contact@cityandyou.com">contactez-nous</a>.</p>
          <a className="phone-number-desktop" href="tel:+33184604442"><i className="fas fa-phone"></i><strong>01 84 60 44 42</strong></a>
        </div>
      </div>
    </div>
  </Aside>
)

const Aside = styled.aside`
  background: #f8fafc;
  min-width: 431px;
  padding: 12px 28px;
  display: flex;
  flex-direction: column;
  color: ${props => props.theme.candyPrimary};

  .floating {
    position: fixed;
    padding: 0 28px;
    top: 12px;
    left: 0;
    display: flex;
    flex-direction: column;
    max-width: 432px;
  }

  nav img {
    height: 54px;
  }

  ul {
    font-size: 14px;
  }

  .team-aside {
    margin-top: 96px;
    display: flex;


    img {
      height: 78px;
      width: 78px;
      margin-right: 18px;
    }

    .team-text {
      font-size: 16px;
      line-height: 1.25;

      strong {
        font-size: 18px;
      }

      p {
        margin: 4px 0;
      }

      p a {
        font-size: 16px;
        text-decoration: underline;
      }

      i {
        transform: rotate(90deg);
        font-size: 20px;
        margin-right: 12px;
      }

      a {
        text-decoration: none;
      }

      a strong {
        font-size: 24px;
      }
    }
  }

  @media ${device.laptop} {
    display: none;
  }
`

export default Sidebar
