import React from 'react'
import { Header, Footer, ReportWrapper, simplifiedGeometry } from './Shared'
import GeneralSummary from './GeneralSummary'
import CitySummary from './CitySummary'
import PlotDetails from './PlotDetails'
import Owners from './Owners'
import PluDetails from './PluDetails'
import BuildingPermits from './BuildingPermits'
import ReportSimulation from './ReportSimulation'

const LandReport = ({
  buildingPermits,
  city,
  googleApiKey,
  inseeData,
  mapboxToken,
  mapTypeLight,
  mapTypeSatellite,
  mapTypeProspection,
  pages,
  plot,
  simulations
}) => {
  const multiPlots = plot.land_registry_id_short?.length > 1
  const plotGeometry = simplifiedGeometry(plot.geometry_to_geojson, 0.00000005)

  return (
    <ReportWrapper>
      { pages.includes(0) && (
        <div className="pdf-content">
          <Header address={plot.postal_address} title="Synthèse immobilière et foncière" />
          <GeneralSummary
            city={city}
            mapboxToken={mapboxToken}
            mapType={mapTypeProspection}
            plot={plot}
            plotGeometry={plotGeometry}
            report="landReport"
            multiPlots={multiPlots}
          />
          <Footer />
        </div>
      )}

      { pages.includes(1) && (
        <div className="pdf-content">
          <Header address={plot.postal_address} title="Synthèse générale sur la ville" />
          <CitySummary
            city={city}
            inseeData={inseeData}
            plot={plot}
            mapboxToken={mapboxToken}
            mapTypeLight={mapTypeLight}
            report="landReport"
          />
          <Footer />
        </div>
      )}

      { pages.includes(2) && (
        <div className="pdf-content">
          <Header address={plot.postal_address} title={`Caractéristiques ${multiPlots ? 'des biens' : 'du bien'}`} />
          <PlotDetails
            googleApiKey={googleApiKey}
            plot={plot}
            plotGeometry={plotGeometry}
            mapboxToken={mapboxToken}
            mapTypeSatellite={mapTypeSatellite}
            multiPlots={multiPlots}
          />
          <Footer />
        </div>
      )}

      { pages.includes(3) && (
        <Owners
          address={plot.postal_address}
          owners={plot.owners}
        />
      )}

      { pages.includes(4) && (
        <div className="pdf-content">
          <Header address={plot.postal_address} title="Urbanisme" />
          <PluDetails
            city={city}
            mapboxToken={mapboxToken}
            mapTypeProspection={mapTypeProspection}
            plot={plot}
            plotGeometry={plotGeometry}
          />
          <Footer />
        </div>
      )}

      { pages.includes(5) && buildingPermits && (
        <div className="pdf-content">
          <Header address={plot.postal_address} title="Permis de construire" />
          <BuildingPermits
            mapboxToken={mapboxToken}
            mapTypeLight={mapTypeLight}
            buildingPermits={buildingPermits}
            plot={plot}
          />
          <Footer />
        </div>
      )}

      { pages.includes(6) && simulations && (
        <div className="pdf-content">
          <Header address={plot.postal_address} title="Simulation de projet" />
          <ReportSimulation
            mapboxToken={mapboxToken}
            mapTypeSatellite={mapTypeSatellite}
            multiPlots={multiPlots}
            plot={plot}
            plotGeometry={plotGeometry}
            simulations={simulations}
          />
          <Footer />
        </div>
      )}
    </ReportWrapper>
  )
}

export default LandReport
