import React, { useEffect } from 'react'
import Chart from 'chart.js/auto'
import { roomsDistribution, completionYearDistribution } from './ChartDatas'
import { BaseTable, doubleHead, percent, singleHead, subHead, Asterich } from './Shared'

const RealEstateData = ({ depInseeData, inseeData }) => {
  useEffect(() => {
    const ctx = document.getElementById('roomsDistribution').getContext('2d')
    new Chart(ctx, roomsDistribution(inseeData.rooms_distribution, depInseeData.rooms_distribution))
  }, [])

  useEffect(() => {
    const ctx = document.getElementById('completionYearDistribution').getContext('2d')
    new Chart(ctx, completionYearDistribution(inseeData.completion_year_distribution, depInseeData.completion_year_distribution))
  }, [])

  return (
    <div className="pdf-content-inside d-flex flex-column">
      <BaseTable className="col-3 mb-12">
        {singleHead('Catégories et types de logements')}
        <tbody>
          {subHead()}
          <tr>
            <td className="bold">Nombre total de logements :</td>
            <td>{inseeData.housing_type_distribution.ens?.toLocaleString('fr-FR')}</td>
            <td className="no-bold">{depInseeData.housing_type_distribution.ens?.toLocaleString('fr-FR')}</td>
          </tr>
          <tr>
            <td>Part des résidences principales :</td>
            {percent(inseeData, 'housing_type_distribution', 'main')}
            {percent(depInseeData, 'housing_type_distribution', 'main', 'no-bold')}
          </tr>
          <tr>
            <td>Part des résidences secondaires :</td>
            {percent(inseeData, 'housing_type_distribution', 'secondary', 'no-bold')}
            {percent(depInseeData, 'housing_type_distribution', 'secondary', 'no-bold')}
          </tr>
          <tr>
            <td>Part des logements vacants :</td>
            {percent(inseeData, 'housing_type_distribution', 'vacant')}
            {percent(depInseeData, 'housing_type_distribution', 'vacant', 'no-bold')}
          </tr>
          <tr className="empty" />
          <tr>
            <td>Part des propriétaires de leur résidence principale :</td>
            {percent(inseeData, 'occupation_distribution', 'owner')}
            {percent(depInseeData, 'occupation_distribution', 'owner', 'no-bold')}
          </tr>
          <tr className="empty" />
          <tr>
            <td>Part de logements sociaux :</td>
            {percent(inseeData, 'occupation_distribution', 'hlm_tenant')}
            {percent(depInseeData, 'occupation_distribution', 'hlm_tenant', 'no-bold')}
          </tr>
        </tbody>
      </BaseTable>

      <div className="page-2-col mb-12">
        <BaseTable className="col-3">
          {doubleHead('Résidences principales', 'selon le nbre de pièces')}
          <tbody>
            {subHead()}
            <tr>
              <td className="bold">Nbre de résidences principales :</td>
              <td>{inseeData.rooms_distribution.ens?.toLocaleString('fr-FR')}</td>
              <td className="no-bold">{depInseeData.rooms_distribution.ens?.toLocaleString('fr-FR')}</td>
            </tr>
            <tr>
              <td>1 pièce :</td>
              {percent(inseeData, 'rooms_distribution', 't1')}
              {percent(depInseeData, 'rooms_distribution', 't1', 'no-bold')}
            </tr>
            <tr>
              <td>2 pièces :</td>
              {percent(inseeData, 'rooms_distribution', 't2')}
              {percent(depInseeData, 'rooms_distribution', 't2', 'no-bold')}
            </tr>
            <tr>
              <td>3 pièces :</td>
              {percent(inseeData, 'rooms_distribution', 't3')}
              {percent(depInseeData, 'rooms_distribution', 't3', 'no-bold')}
            </tr>
            <tr>
              <td>4 pièces :</td>
              {percent(inseeData, 'rooms_distribution', 't4')}
              {percent(depInseeData, 'rooms_distribution', 't4', 'no-bold')}
            </tr>
            <tr>
              <td>5 pièces ou plus :</td>
              {percent(inseeData, 'rooms_distribution', 't5')}
              {percent(depInseeData, 'rooms_distribution', 't5', 'no-bold')}
            </tr>
          </tbody>
        </BaseTable>

        <div>
          <canvas id="roomsDistribution" />
        </div>
      </div>

      <div className="page-2-col mb-12">
        <BaseTable className="col-3">
          {doubleHead('Résidences principales', "selon la période d'achèvement")}
          <tbody>
            {subHead()}
            <tr>
              <td>Ensemble avant 2018 :</td>
              <td>{inseeData.completion_year_distribution.ens?.toLocaleString('fr-FR')}</td>
              <td className="no-bold">{depInseeData.completion_year_distribution.ens?.toLocaleString('fr-FR')}</td>
            </tr>
            <tr>
              <td>Avant 1919 :</td>
              {percent(inseeData, 'completion_year_distribution', 'b1919')}
              {percent(depInseeData, 'completion_year_distribution', 'b1919', 'no-bold')}
            </tr>
            <tr>
              <td>De 1919 à 1945 :</td>
              {percent(inseeData, 'completion_year_distribution', 'f1919')}
              {percent(depInseeData, 'completion_year_distribution', 'f1919', 'no-bold')}
            </tr>
            <tr>
              <td>De 1946 à 1970 :</td>
              {percent(inseeData, 'completion_year_distribution', 'f1946')}
              {percent(depInseeData, 'completion_year_distribution', 'f1946', 'no-bold')}
            </tr>
            <tr>
              <td>De 1971 à 1990 :</td>
              {percent(inseeData, 'completion_year_distribution', 'f1971')}
              {percent(depInseeData, 'completion_year_distribution', 'f1971', 'no-bold')}
            </tr>
            <tr>
              <td>De 1991 à 2005 :</td>
              {percent(inseeData, 'completion_year_distribution', 'f1991')}
              {percent(depInseeData, 'completion_year_distribution', 'f1991', 'no-bold')}
            </tr>
            <tr>
              <td>De 2006 à 2017 :</td>
              {percent(inseeData, 'completion_year_distribution', 'f2006')}
              {percent(depInseeData, 'completion_year_distribution', 'f2006', 'no-bold')}
            </tr>
          </tbody>
        </BaseTable>

        <div>
          <canvas id="completionYearDistribution" />
        </div>
      </div>

      <div className="page-2-col align-end">
        {/* <BaseTable className="col-3">
          {singleHead('Equipement automobile des ménages')}
          <tbody>
            {subHead()}
            <tr>
              <td>Part des ménages avec au moins un empl. réservé au stationnement :</td>
              {percent(inseeData, 'parking_equipment', 'parking')}
              {percent(depInseeData, 'parking_equipment', 'parking')}
            </tr>
            <tr className="empty" />
            <tr>
              <td>Part des ménages avec :</td>
            </tr>
            <tr>
              <td className="bullet">0 voiture :</td>
              {percent(inseeData, 'car_equipment', 'v0')}
              {percent(depInseeData, 'car_equipment', 'v0')}
            </tr>
            <tr>
              <td className="bullet">1 voiture :</td>
              {percent(inseeData, 'car_equipment', 'v1')}
              {percent(depInseeData, 'car_equipment', 'v1')}
            </tr>
            <tr>
              <td className="bullet">2 voitures ou + :</td>
              {percent(inseeData, 'car_equipment', 'v2')}
              {percent(depInseeData, 'car_equipment', 'v2')}
            </tr>
          </tbody>
        </BaseTable> */}

        <BaseTable className="col-3 h-100">
          {singleHead('Type de résidences principales')}
          <tbody>
            {subHead('Part de logts', 'Nb de pièces')}
            <tr>
              <td>Appartements :</td>
              {percent(inseeData, 'main_house_distribution', 'houses', '', true)}
              <td>{inseeData.rooms_size_mean.appartments?.toLocaleString('fr-FR', { maximumFractionDigits: 1 })}</td>
            </tr>
            <tr>
              <td>Maisons :</td>
              {percent(inseeData, 'main_house_distribution', 'houses')}
              <td>{inseeData.rooms_size_mean.houses?.toLocaleString('fr-FR', { maximumFractionDigits: 1 })}</td>
            </tr>
          </tbody>
        </BaseTable>
      </div>
      <Asterich flexGrow>
        <div>
          <span className="color-candy-primary">*</span>
          Données issues du recensement INSEE 2020
          <br />
        </div>
      </Asterich>
    </div>
  )
}

export default RealEstateData
