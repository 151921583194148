import React, { useEffect } from 'react'
import Chart from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { BaseTable, doubleHead, singleHead, col5Table } from './Shared'
import { stocksDistribution, reservationsDistribution } from './ChartDatas'

Chart.register(ChartDataLabels)

const NewRealEstateData = ({ city, plot }) => {
  const confidenceIndex = parseInt(plot.city_price_new["confidence_index"]["test"])

  useEffect(() => {
    const ctx = document.getElementById('stocksDistribution').getContext('2d')
    new Chart(ctx, stocksDistribution(city.available_lots_by_typo, '#D95D57'))
    const ctx2 = document.getElementById('reservationsDistribution').getContext('2d')
    new Chart(ctx2, reservationsDistribution(city.sold_lots_by_typo, '#639A5F'))
  }, [])

  return (
    <div className="pdf-content-inside">
      <div className="sub-header">Sur le secteur</div>
      <BaseTable className="green-background mb-12">
        {singleHead('Prix de vente moyen estimé')}
        {col5Table(plot.city_price_new_by_typo, confidenceIndex, true)}
      </BaseTable>

      <div className="sub-header">Sur la commune</div>

      <div className="page-2-col-stretch ali">
        <BaseTable>
          {singleHead('Stock par typologie ')}
          {col5Table(city.available_lots_by_typo)}
        </BaseTable>

        <div>
          <canvas id="stocksDistribution" className="canvas-border" />
        </div>
      </div>

      <div className="page-2-col-stretch">
        <BaseTable>
          {doubleHead('Réservation par typologie', 'Au cours des 12 derniers mois', '3')}
          {col5Table(city.sold_lots_by_typo)}
        </BaseTable>

        <div>
          <canvas id="reservationsDistribution" className="canvas-border" />
        </div>
      </div>

      <div className="page-2-col">
        <BaseTable className="col-2">
          {doubleHead('Évolution constatée des prix de vente')}
          <tbody>
            <tr>
              <td>Sur les 12 derniers mois :</td>
              <td className="no-bold">{city.price_trend_12 === 0 || city.price_trend_12 === null ? '-' : `${city.price_trend_12?.change} %`}</td>
            </tr>
            <tr>
              <td>Sur les 6 derniers mois :</td>
              <td className="no-bold">{city.price_trend_6 === 0 || city.price_trend_6 === null ? '-' : `${city.price_trend_6?.change} %`}</td>
            </tr>
          </tbody>
        </BaseTable>
      </div>
    </div>
  )
}

export default NewRealEstateData
